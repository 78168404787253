import React, { useState } from 'react'

import { Grid, Typography } from '@mui/material'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import { useTheme } from '@mui/material/styles'
import { Cell, Pie, PieChart } from 'recharts'
import { HomeOutlined } from '@mui/icons-material'
import { MetricSkeleton } from '@components/common/Skeletons'
import CommonModal from '@components/common/Modal/CommonModal'

export default function Occupation(props) {
  const { data } = props
  const { dictionary } = useTranslation()
  const theme = useTheme()
  const [detailModal, setDetailModal] = useState({
    open: false
  })

  const occupancy_rate =
    (data &&
      Math.round(
        (((data.rent_year_actual * 100) / data.rent_year_potential) * 100) / 100
      )) ||
    0

  const formattedData = [
    {
      name: dictionary.occupied,
      value: occupancy_rate,
      color: theme.palette.highlight.violet
    },
    {
      name: dictionary.vacant,
      value: 100 - occupancy_rate,
      color: theme.palette.secondary.light
    }
  ]

  if (!data)
    return (
      <DashboardCard>
        <MetricSkeleton />
      </DashboardCard>
    )

  return (
    <>
      <DashboardCard>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h6"
              fontSize={18}
              data-testid="Typography-48572f82-1a46-4971-b47b-862932170fd5"
            >
              {dictionary.occupancy_rate}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Grid
              container
              spacing={3}
              direction={'column'}
              alignItems={'space-around'}
            >
              <Grid item sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <HomeOutlined sx={{ color: '#6B6E75' }} />
                <Typography
                  fontSize={12}
                  onClick={() =>
                    setDetailModal({
                      open: true,
                      header: dictionary.total_lots
                    })
                  }
                  data-testid="Typography-1b7a87fa-b648-4298-80a8-d2c93347d528"
                  color="#6B6E75"
                >
                  <b style={{ fontSize: 14 }}>{data?.lot_count}</b>{' '}
                  {dictionary.lots_in_total}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: 'flex', gap: 2, alignItems: 'center' }}
              >
                <div
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      borderRadius: '50%',
                      backgroundColor: '#476ff2'
                    }}
                  />
                </div>
                <Typography
                  fontSize={12}
                  onClick={() =>
                    setDetailModal({
                      open: true,
                      header: dictionary.vacant_lots
                    })
                  }
                  data-testid="Typography-f506cbf2-aa58-4bc3-bd11-c1a3d8657bd7"
                  color="#6B6E75"
                >
                  <b style={{ fontSize: 14 }}>{data?.vacant_lot_count}</b>{' '}
                  {dictionary.vacant_lots}
                </Typography>
              </Grid>
              <Grid item sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <div
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <div
                    style={{
                      width: '15px',
                      height: '15px',
                      borderRadius: '50%',
                      backgroundColor: '#69c3f8'
                    }}
                  />
                </div>
                <Typography
                  fontSize={12}
                  onClick={() =>
                    setDetailModal({
                      open: true,
                      header: dictionary.occupied_lots
                    })
                  }
                  data-testid="Typography-36c426fc-ec5b-420a-a014-c518b7c36825"
                  color="#6B6E75"
                >
                  <b style={{ fontSize: 14 }}>{data?.occupied_lot_count}</b>{' '}
                  {dictionary.occupied_lots}
                </Typography>
              </Grid>
            </Grid>
            <PieChart width={250} height={250}>
              <Pie
                data={formattedData}
                innerRadius={70}
                outerRadius={90}
                fill={theme.palette.primary.main}
                dataKey="value"
                stroke=""
              >
                {formattedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <text
                x={117}
                y={115}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={30}
                fontWeight={600}
              >
                {occupancy_rate}
              </text>
              <text
                x={occupancy_rate === 100 ? 145 : 137}
                y={122}
                fill={theme.palette.text.primary}
              >
                %
              </text>
              <text
                x={125}
                y={145}
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={15}
                fill={theme.palette.text.primary}
              >
                {dictionary.occupancy}
              </text>
            </PieChart>
          </Grid>
        </Grid>
      </DashboardCard>
      <CommonModal
        open={detailModal.open}
        title={detailModal.header}
        maxWidth={'md'}
        onClose={() => setDetailModal({ open: false })}
        isAlignTop
      >
        {dictionary.coming_soon}
      </CommonModal>
    </>
  )
}
