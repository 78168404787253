import React from 'react'

import { Grid, Typography } from '@mui/material'
import {
  Area,
  Line,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart
} from 'recharts'
import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import numberWithCommas from '@utils/numberWithCommas'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/system'
import { MetricSkeleton } from '@components/common/Skeletons'

export default function Expenses(props) {
  const { data, currency } = props
  const { dictionary } = useTranslation()
  const theme = useTheme()

  if (!data)
    return (
      <DashboardCard>
        <MetricSkeleton />
      </DashboardCard>
    )

  const rechartData = Object.keys(data?.time_series).map(key => {
    const monthData = data?.time_series[key]
    return {
      name: monthData.month,
      value: monthData.unpaid_expenses
    }
  })

  return (
    <DashboardCard>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            fontSize={18}
            data-testid="Typography-bdc1b4aa-4f88-4feb-95ae-bff628e5fe45"
          >
            {dictionary.expenses}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} height="100%">
            <Grid item xs={4}>
              <Stack
                gap={3}
                justifyContent="end"
                alignItems="start"
                height="100%"
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography
                    variant="h5"
                    fontWeight={600}
                    data-testid="Typography-808d1d0d-0e6d-4491-b978-fc645030dddc"
                  >
                    {data?.unpaid_expenses > 1000
                      ? numberWithCommas(data?.unpaid_expenses / 1000, 1) + 'k'
                      : numberWithCommas(data?.unpaid_expenses)}
                    <span
                      style={{
                        fontSize: '16px',
                        color: theme.typography.subtitle1.color
                      }}
                    >
                      {currency || '€'}
                    </span>
                  </Typography>
                </Stack>
                <Stack gap={0.5}>
                  <Typography
                    color={theme.typography.subtitle1.color}
                    fontSize={15}
                  ></Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={8}>
              <ResponsiveContainer width="100%" height={125}>
                <AreaChart
                  data={rechartData}
                  margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                >
                  <XAxis dataKey="month" hide />
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8884d8" stopOpacity={0.2} />
                      <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />

                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    strokeWidth={2}
                    dot={false}
                  />

                  <Tooltip
                    cursor={{ fill: 'transparent' }}
                    formatter={v => numberWithCommas(v || 0, 2)}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
