import React, { useEffect, useReducer, useState } from 'react'
import PropTypes from 'prop-types'

import { Divider, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import { Login, Logout, Redo } from '@mui/icons-material'
import { Stack } from '@mui/system'
import { MetricSkeleton } from '@components/common/Skeletons'
import CommonModal from '@components/common/Modal/CommonModal'
import LeasesModal from '../DashboardModals/LeasesModal'
import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import { queriesReducer } from '@utils/commonReducerFunctions'
import formatURL from '@utils/formatURL'

export default function Leases(props) {
  const { leasesData, occupancyData, operation } = props

  const { dictionary, t } = useTranslation()
  const theme = useTheme()
  const [detailModal, setDetailModal] = useState({
    open: false,
    id: null
  })
  const [leavingCount, setLeavingCount] = useState(0)
  const [enteringCount, setEnteringCount] = useState(0)
  const [renewalCount, setRenewalCount] = useState(0)

  const currentDate = new Date()
  const threeMonthsFromNow = new Date()
  threeMonthsFromNow.setMonth(currentDate.getMonth() + 3)

  const [filterQueries, dispatchFilterQueries] = useReducer(queriesReducer, {})
  const leasePeriodsURL = formatURL(API_URL.LEASES.GET_LEASES_PERIODS(), {
    ...filterQueries,
    ...(operation && { operation: operation })
  })

  const { data: leasePeriodsData, isLoading: isLeasePeriodsLoading } =
    useFetchData(leasePeriodsURL)

  const [leasePeriodsLeaving, setLeasePeriodsLeaving] = useState([])
  useEffect(() => {
    setLeasePeriodsLeaving(
      leasePeriodsData?.filter(period => {
        const endDateString = period?.end_date
        if (endDateString) {
          const endDate = new Date(
            endDateString?.split('-')?.reverse().join('-')
          )
          return (
            endDate >= currentDate &&
            endDate <= threeMonthsFromNow &&
            period?.is_last_period
          )
        }
        return false
      })
    )
  }, [leasePeriodsData])

  const [leasePeriodsStarting, setLeasePeriodsStarting] = useState([])
  useEffect(() => {
    setLeasePeriodsStarting(
      leasePeriodsData?.filter(period => {
        const startDateString = period?.start_date
        if (startDateString) {
          const startDate = new Date(
            startDateString?.split('-')?.reverse().join('-')
          )
          return (
            startDate >= currentDate &&
            startDate <= threeMonthsFromNow &&
            period?.is_first_period
          )
        }
        return false
      })
    )
  }, [leasePeriodsData])

  const [leasePeriodsRenewed, setLeasePeriodsRenewed] = useState([])
  useEffect(() => {
    setLeasePeriodsRenewed(
      leasePeriodsData?.filter(period => {
        const startDate = new Date(
          period?.start_date?.split('-').reverse().join('-')
        )
        const startDateWithinNext3Months =
          startDate >= currentDate && startDate <= threeMonthsFromNow
        const linkedPeriodExists = leasePeriodsData?.some(otherPeriod => {
          const otherEndDate = new Date(
            otherPeriod?.end_date?.split('-').reverse().join('-')
          )
          return (
            otherPeriod?.lease?.leaseId === period?.lease?.leaseId &&
            otherEndDate >= currentDate &&
            otherEndDate <= threeMonthsFromNow
          )
        })
        return startDateWithinNext3Months && linkedPeriodExists
      })
    )
  }, [leasePeriodsData])

  useEffect(() => {
    setLeavingCount(leasePeriodsLeaving?.length || 0)
    setEnteringCount(leasePeriodsStarting?.length || 0)
    setRenewalCount(leasePeriodsRenewed?.length || 0)
  }, [leasePeriodsLeaving, leasePeriodsStarting, leasePeriodsRenewed])

  if (!occupancyData)
    return (
      <DashboardCard>
        <MetricSkeleton />
      </DashboardCard>
    )

  return (
    <>
      <DashboardCard>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                variant="h6"
                fontSize={18}
                data-testid="Typography-8e76a099-0c0a-4178-9c07-a22d4ace10fc"
              >
                {dictionary.leases_movements}
              </Typography>
              <Stack direction="row" alignItems="center" gap={5}>
                {/* <Stack direction="row" alignItems="center" gap={1}>
                  <div
                   style={{
                     width: '10px',
                     height: '10px',
                     borderRadius: '50%',
                     backgroundColor: '#476ff2'
                   }}
                  />
                  <Typography fontSize={12}>{dictionary.exiting}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1}>
                  <div
                   style={{
                     width: '10px',
                     height: '10px',
                     borderRadius: '50%',
                     backgroundColor: '#d378f3'
                   }}
                  />
                  <Typography fontSize={12}>{dictionary.planned}</Typography>
                  </Stack>
                  <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{
                   backgroundColor: theme.palette.elements.light,
                   padding: 0.3,
                   borderRadius: '8px',
                   cursor: 'pointer'
                  }}
                  >
                  <MoreHoriz />
                  </Stack> */}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="center" gap={4}>
              <Stack
                alignItems="center"
                gap={1.5}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  setDetailModal({
                    open: true,
                    header: dictionary.tenants_leaving,
                    subheader: t('dashboard_rents_evolution_leaving_subtitle')
                  })
                }
              >
                <Logout
                  sx={{ color: theme.palette.primary.main, fontSize: 30 }}
                />

                <Typography
                  fontSize={13}
                  fontWeight={600}
                  color={theme.typography.subtitle1.color}
                  data-testid="Typography-c4fb4bd0-63e3-4d15-b35f-9250f14e2f78"
                >
                  {dictionary.tenants_leaving}
                </Typography>
                <Typography
                  fontSize={20}
                  fontWeight={700}
                  marginTop={2}
                  data-testid="Typography-a0cf664f-69fb-4ec7-b475-899fac5406a7"
                >
                  {leavingCount}
                </Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack
                alignItems="center"
                gap={1.5}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  setDetailModal({
                    open: true,
                    header: dictionary.tenants_arriving,
                    subheader: t('dashboard_rents_evolution_entering_subtitle')
                  })
                }
              >
                <Login
                  style={{ color: theme.palette.primary.main, fontSize: 30 }}
                />

                <Typography
                  fontSize={13}
                  fontWeight={600}
                  color={theme.typography.subtitle1.color}
                  data-testid="Typography-f784f7fd-cd77-44b1-ae47-ee7e7166b582"
                >
                  {dictionary.tenants_arriving}
                </Typography>
                <Typography
                  fontSize={20}
                  fontWeight={700}
                  marginTop={2}
                  data-testid="Typography-a61983f7-819a-45c4-be58-7219445e4510"
                >
                  {enteringCount}
                </Typography>
              </Stack>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack
                alignItems="center"
                gap={1.5}
                sx={{ cursor: 'pointer' }}
                onClick={() =>
                  setDetailModal({
                    open: true,
                    header: dictionary.contracts_renewing,
                    subheader: t('dashboard_rents_evolution_renewal_subtitle')
                  })
                }
              >
                <Redo
                  style={{ color: theme.palette.primary.main, fontSize: 30 }}
                />

                <Typography
                  fontSize={13}
                  fontWeight={600}
                  color={theme.typography.subtitle1.color}
                  data-testid="Typography-3a9a8002-061c-4c91-b178-6907112d512b"
                >
                  {dictionary.contracts_renewing}
                </Typography>
                <Typography
                  fontSize={20}
                  fontWeight={700}
                  marginTop={2}
                  data-testid="Typography-b5277395-f9fe-4165-be99-e564b05b5160"
                >
                  {renewalCount}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </DashboardCard>

      <CommonModal
        open={detailModal.open}
        title={detailModal.header}
        subtitle={detailModal.subheader}
        maxWidth={'md'}
        onClose={() => {
          setDetailModal({ open: false })
          dispatchFilterQueries({ operation: null })
        }}
        isAlignTop
      >
        <LeasesModal
          isLeasePeriodsLoading={isLeasePeriodsLoading}
          title={detailModal.header}
          leasePeriodsLeaving={leasePeriodsLeaving}
          leasePeriodsStarting={leasePeriodsStarting}
          leasePeriodsRenewed={leasePeriodsRenewed}
          leases={leasesData?.results}
          filterQueries={filterQueries}
          dispatchFilterQueries={dispatchFilterQueries}
          operationId={operation}
        />
      </CommonModal>
    </>
  )
}

Leases.propTypes = {
  leasesData: PropTypes.object,
  occupancyData: PropTypes.object,
  operation: PropTypes.number
}
