import React from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import abbreviateNumber from '@utils/abbreviateNumber'
import {
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart
} from 'recharts'
import numberWithCommas from '@utils/numberWithCommas'
import { MetricSkeleton } from '@components/common/Skeletons'
import { useTheme } from '@mui/material/styles'
import Link from 'next/link'
import routes from '@config/routes'

export default function RentsCharges(props) {
  const { data, currency, queries } = props
  const { dictionary, t } = useTranslation()
  const theme = useTheme()

  const translateMonth = month => {
    switch (month) {
      case 'Jan':
        return t('month_names.0')
      case 'Fev':
        return t('month_names.1')
      case 'Mars':
        return t('month_names.2')
      case 'Avr':
        return t('month_names.3')
      case 'Mai':
        return t('month_names.4')
      case 'Juin':
        return t('month_names.5')
      case 'Jui':
        return t('month_names.6')
      case 'Août':
        return t('month_names.7')
      case 'Sept':
        return t('month_names.8')
      case 'Oct':
        return t('month_names.9')
      case 'Nov':
        return t('month_names.10')
      case 'Dec':
        return t('month_names.11')
      default:
        return month
    }
  }

  return (
    <DashboardCard>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {data ? (
            <Stack>
              <Grid container>
                <Grid item xs={6} px={1.5}>
                  <Typography
                    fontSize={13}
                    data-testid="Typography-c6e60e49-61de-4a11-b0c0-9a48c5800b03"
                  >
                    {dictionary.rent}
                  </Typography>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    paddingTop={1}
                  >
                    <div
                      style={{
                        width: '8px',
                        height: '8px',
                        borderRadius: '50%',
                        backgroundColor: '#459ced'
                      }}
                    />

                    <Typography
                      variant="h6"
                      fontWeight={700}
                      data-testid="Typography-539d00d3-6bb8-4ee4-9c5a-d8e2fac217d7"
                    >
                      {numberWithCommas(data?.revenue_actual, 0)}
                      <span
                        style={{
                          fontSize: '14px',
                          color: theme.typography.subtitle1.color
                        }}
                        data-testid="span-59cd9c65-861c-4e1e-8411-285818be2baf"
                      >
                        {currency || '€'}
                      </span>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  px={1.5}
                  sx={{
                    cursor: queries.operation ? 'pointer' : null,
                    '&:hover': {
                      backgroundColor: queries.operation
                        ? theme.palette.primary.light
                        : null,
                      borderRadius: queries.operation ? '10px' : null,
                      transition: '0.3s ease-in-out'
                    }
                  }}
                >
                  <Typography
                    fontSize={13}
                    data-testid="Typography-9b168ea2-0315-4e20-87ee-fea63e15fe85"
                  >
                    {dictionary.expenses}
                  </Typography>
                  <Link
                    href={
                      queries.operation
                        ? `${routes.project.expenses.expenses}/${queries.operation}`
                        : ''
                    }
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      paddingTop={1}
                    >
                      <div
                        style={{
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor: '#d377f3'
                        }}
                      />

                      <Typography
                        variant="h6"
                        fontWeight={700}
                        data-testid="Typography-cf64175c-c2b8-4eb7-93f3-3b3a5eeea677"
                      >
                        {numberWithCommas(data?.expenses_actual, 0)}
                        <span
                          style={{
                            fontSize: '14px',
                            color: theme.typography.subtitle1.color
                          }}
                          data-testid="span-7ec7adb8-479d-4a1a-a3c8-737bc40eb83c"
                        >
                          {currency || '€'}
                        </span>
                      </Typography>
                    </Stack>
                  </Link>
                </Grid>
              </Grid>
              <ResponsiveContainer width="100%" height={180}>
                <AreaChart
                  data={data?.time_series}
                  margin={{ top: 30, right: 0, bottom: 0 }}
                >
                  <CartesianGrid
                    stroke="#ccc"
                    strokeDasharray=""
                    vertical={false}
                    horizontal
                  />

                  <XAxis
                    dataKey="month"
                    fontSize={12}
                    axisLine={false}
                    tick={{ fill: '#A3A5AB' }}
                    tickLine={false}
                  />

                  <YAxis
                    type="number"
                    allowDecimals={false}
                    tick={{ fill: '#A3A5AB' }}
                    tickCount={4}
                    tickFormatter={value => abbreviateNumber(value)}
                    fontSize={12}
                    axisLine={false}
                    tickLine={false}
                    width={40}
                  />

                  <Tooltip
                    cursor={{ fill: 'transparent' }}
                    formatter={v => numberWithCommas(v || 0, 2)}
                    content={tooltipProps => {
                      const { active, payload } = tooltipProps
                      if (active && payload && payload.length) {
                        const data = payload[0].payload
                        return (
                          <div
                            style={{
                              backgroundColor: 'white',
                              padding: 10,
                              border: '1px solid #ccc',
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 8
                            }}
                          >
                            <p style={{ fontSize: 15 }}>
                              {translateMonth(data?.month)}
                            </p>
                            <p style={{ fontSize: 15, color: '#459ced' }}>
                              {t('rent')}: {data?.revenue}
                            </p>
                            <p style={{ fontSize: 15, color: '#d377f3' }}>
                              {t('expenses')}: {data?.expenses}
                            </p>
                          </div>
                        )
                      }
                      return null
                    }}
                  />

                  <defs>
                    <linearGradient
                      id="colorRevenue"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#459CED" stopOpacity={0.2} />
                      <stop offset="95%" stopColor="#459CED" stopOpacity={0} />
                    </linearGradient>
                    <linearGradient
                      id="colorExpenses"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#d377f3" stopOpacity={0.2} />
                      <stop offset="95%" stopColor="#d377f3" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Area
                    dataKey="revenue"
                    type="monotone"
                    stroke="#459CED"
                    fillOpacity={1}
                    fill="url(#colorRevenue)"
                  />

                  <Line dataKey="revenue" stroke="#459CED" dot={false} />

                  <Area
                    dataKey="expenses"
                    type="monotone"
                    stroke="#d377f3"
                    fillOpacity={1}
                    fill="url(#colorExpenses)"
                  />

                  <Line dataKey="expenses" stroke="#d377f3" dot={false} />
                </AreaChart>
              </ResponsiveContainer>
            </Stack>
          ) : (
            <MetricSkeleton width="100%" mb="0" />
          )}
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
