import React, { useState } from 'react'
import { Grid, Typography } from '@mui/material'
import DashboardCard from './DashboardCard'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/system'
import { MetricSkeleton } from '@components/common/Skeletons'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import RemoveIcon from '@mui/icons-material/Remove'
import numberWithCommas from '@utils/numberWithCommas'
import Dialog from '@mui/material/Dialog'
import PlaceHolder from '@components/common/PlaceHolder'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

export default function Metric(props) {
  const { indicator } = props
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (!indicator)
    return (
      <DashboardCard>
        <MetricSkeleton />
      </DashboardCard>
    )

  return (
    <>
      <Grid
        container
        spacing={1}
        onClick={handleOpen}
        style={{ cursor: 'pointer' }}
      >
        <DashboardCard>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                height="100%"
                style={{ position: 'relative' }}
              >
                <Grid item xs={12}>
                  <Stack
                    gap={3}
                    justifyContent="end"
                    alignItems="start"
                    height="100%"
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <Typography
                        variant="h5"
                        fontWeight={600}
                        data-testid="Typography-89f1ca62-9ffc-4f2f-9c68-6587749b3e23"
                      >
                        {numberWithCommas(indicator?.value)}
                        <span
                          style={{
                            fontSize: '16px',
                            marginLeft: '4px',
                            color: theme.typography.subtitle1.color
                          }}
                        >
                          {indicator?.unit}
                        </span>
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <div style={{ position: 'absolute', top: 10, right: 10 }}>
                  {indicator?.change > 0 ? (
                    <ArrowUpwardIcon
                      style={{
                        color: indicator?.goal === 'min' ? 'red' : 'green'
                      }}
                    />
                  ) : indicator?.change < 0 ? (
                    <ArrowDownwardIcon
                      style={{
                        color: indicator?.goal === 'min' ? 'green' : 'red'
                      }}
                    />
                  ) : (
                    <RemoveIcon
                      style={{ color: theme.palette.action.disabled }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                fontSize={18}
                data-testid="Typography-ff356072-e656-485f-8d8c-ee3a5d918efa"
              >
                {indicator?.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                fontSize={12}
                data-testid="Typography-ff356072-e656-485f-8d8c-ee3a5d918efa"
              >
                {indicator?.description}
              </Typography>
            </Grid>
          </Grid>
        </DashboardCard>
      </Grid>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Typography
          variant="h6"
          component="div"
          style={{ margin: theme.spacing(2) }}
        >
          Historical Data
        </Typography>
        {indicator.past_data && indicator.past_data.length > 0 ? (
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={indicator.past_data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                label={{
                  value: 'Date',
                  position: 'insideBottomRight',
                  offset: -10
                }}
              />
              <YAxis
                label={{
                  value: indicator.unit,
                  angle: -90,
                  position: 'insideLeft'
                }}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="value"
                stroke={theme.palette.primary.main}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Typography
            variant="subtitle1"
            style={{ margin: theme.spacing(2), textAlign: 'center' }}
          >
            <PlaceHolder src="/Placeholders/Keysy/Dépenses.png" />
            No historical snapshots available yet.
          </Typography>
        )}
      </Dialog>
    </>
  )
}
