import React, { useEffect, useState } from 'react'

import {
  Chip,
  CircularProgress,
  Grid,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material'

import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'
import PlaceHolder from '@components/common/PlaceHolder'
import { ArrowForward, CalendarToday } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { format, parse } from 'date-fns'
import { Box } from '@mui/system'
import CommonModal from '@components/common/Modal/CommonModal'
import TaskForm from '@components/Tasks/TaskForm'
import routes from '@config/routes'

export default function Tasks(props) {
  const { data, isLoading, fetchTasksURL } = props
  const { dictionary } = useTranslation()
  const classesTable = useTableStyles()
  const theme = useTheme()
  const [tasks, setTasks] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [taskId, setTaskId] = useState(null)
  const [isBottom, setIsBottom] = useState(false)

  const handleScroll = e => {
    const bottom =
      Math.abs(
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight
      ) < 1
    if (bottom) setIsBottom(true)
    else setIsBottom(false)
  }

  useEffect(() => {
    if (data) {
      const filteredTasks = data.results.filter(task => !task.is_completed)
      setTasks(filteredTasks)
    }
  }, [data])

  const formatChip = task => {
    if (task.is_completed === true) {
      return (
        <Chip
          label={dictionary.completed}
          sx={{
            height: 24,
            fontSize: 12,
            color: theme.palette.highlight.green,
            backgroundColor: theme.palette.highlight.lightGreen,
            borderRadius: '8px'
          }}
          data-testid="Chip-30bc0ede-56de-4bb7-91c7-a31135b44292"
        />
      )
    }
    if (task.is_completed === false && task.is_overdue === false) {
      return (
        <Chip
          label={dictionary.in_progress}
          sx={{
            height: 24,
            fontSize: 12,
            color: 'primary.main',
            backgroundColor: 'primary.light',
            borderRadius: '8px'
          }}
          data-testid="Chip-39f910f9-4e68-41f2-ab9b-d5901ee55cdf"
        />
      )
    }
    if (task.is_overdue === true) {
      return (
        <Chip
          label={dictionary.issues}
          sx={{
            height: 24,
            fontSize: 12,
            color: theme.palette.error.main,
            backgroundColor: theme.palette.error.light,
            borderRadius: '8px'
          }}
          data-testid="Chip-fa10393c-74b2-403c-a698-19da27c67c96"
        />
      )
    }
  }

  const handleModalClose = () => {
    setIsEdit(false)
    setTaskId(null)
  }

  return (
    <>
      <DashboardCard>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: 1
            }}
          >
            <Typography
              variant="h6"
              fontSize={18}
              paddingLeft={1}
              data-testid="Typography-00a4390f-7e2a-40a9-b8f1-a21c13509a1a"
            >
              {dictionary.tasks}
            </Typography>
            <Link
              href={routes.collaboration.tasks}
              sx={{ textDecoration: 'none' }}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <Typography
                  fontWeight={500}
                  fontSize={12}
                  color={theme.palette.primary.main}
                  data-testid="Typography-17dfe8e2-f231-4a79-8387-8998472bf1f6"
                >
                  {dictionary.see_all_tasks}
                </Typography>
                <ArrowForward
                  fontSize="8"
                  sx={{
                    stroke: theme.palette.primary.main,
                    strokeWidth: 1.5
                  }}
                />
              </Stack>
            </Link>
          </Grid>
          <Grid item xs={12}>
            <TableContainer
              sx={{
                height: 400,
                maskImage: isBottom
                  ? null
                  : 'linear-gradient(black 55%, transparent)'
              }}
              onScroll={handleScroll}
            >
              <Table aria-label="revenue_adjustments_table">
                <TableBody>
                  {isLoading && (
                    <TableRow className={classesTable.row}>
                      <TableCell align="center" colSpan={'100%'}>
                        <CircularProgress color="secondary" />
                      </TableCell>
                    </TableRow>
                  )}

                  {!isLoading && !tasks?.length && (
                    <TableRow hover={false} className={classesTable.row}>
                      <TableCell align="center" colSpan={'100%'}>
                        <PlaceHolder />
                      </TableCell>
                    </TableRow>
                  )}

                  {tasks?.length > 0 &&
                    tasks?.slice(0, 20).map(task => (
                      <TableRow
                        key={task.id}
                        sx={{ cursor: 'pointer', borderRadius: '8px' }}
                        onClick={() => {
                          setIsEdit(true)
                          setTaskId(task.id)
                        }}
                      >
                        <TableCell
                          sx={{
                            border: 'none',
                            paddingBottom: 2,
                            paddingLeft: 1
                          }}
                        >
                          <Stack>
                            <Typography
                              fontSize={14}
                              fontWeight={500}
                              data-testid="Typography-0b4686a2-1cd4-45c6-8db1-444db1dd0af7"
                            >
                              {task.title}
                            </Typography>
                            <Box>
                              <Chip
                                avatar={
                                  <div
                                    style={{
                                      width: '10px',
                                      height: '10px',
                                      borderRadius: '50%',
                                      backgroundColor: 'red',
                                      marginLeft: '7px'
                                    }}
                                  />
                                }
                                label="High"
                                sx={{
                                  fontSize: 11,
                                  height: 20,
                                  color: theme.palette.error.main,
                                  backgroundColor: theme.palette.error.light
                                }}
                                data-testid="Chip-ea43d24f-4785-484f-8a56-45bc2a1939c7"
                              />
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 'none',
                            paddingBottom: 2,
                            paddingX: 0,
                            fontSize: 12,
                            width: '120px'
                          }}
                        >
                          <Stack direction="row" alignItems="center">
                            <CalendarToday
                              sx={{
                                height: '15px',
                                color: theme.typography.subtitle1.color
                              }}
                            />
                            <Typography
                              fontSize={12}
                              fontWeight={500}
                              data-testid="Typography-b409bc5e-026d-4c9d-85a0-dbf0eac149ee"
                            >
                              {task.due_date
                                ? format(
                                    parse(
                                      task.due_date,
                                      'dd-MM-yyyy',
                                      new Date()
                                    ),

                                    'MMM d, yyyy'
                                  )
                                : '--'}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell
                          sx={{
                            border: 'none',
                            paddingBottom: 2,
                            paddingRight: 1,
                            textAlign: 'right'
                          }}
                        >
                          {formatChip(task)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DashboardCard>
      <CommonModal
        title={`${dictionary.edit} ${dictionary.task}`}
        open={isEdit}
        onClose={handleModalClose}
        maxWidth={'md'}
      >
        {isEdit && (
          <TaskForm
            handleClose={handleModalClose}
            isEdit={isEdit}
            taskId={taskId}
            mutateURLArray={[fetchTasksURL]}
          />
        )}
      </CommonModal>
    </>
  )
}
